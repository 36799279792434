



















































































































































































































































































































































































































































































































































































































































































































































import "vue-inner-image-zoom/lib/vue-inner-image-zoom.css";
import InnerImageZoom from "vue-inner-image-zoom";
import { Navigation } from "@/mixins/navigation";
import { Permissions } from "@/mixins/permissions";
import { CustomDate } from "@/mixins/custom-date";
import Component, { mixins } from "vue-class-component";
import Loader from "@/components/General/Loader.vue";
import DetailHeader from "@/components/Layout/Backoffice/DetailHeader.vue";
import { Notification } from "@/models/notification.interface";
import { User } from "@/models/user.interface";
import Empty from "@/components/General/Empty.vue";
import { StyleCheck } from "@/mixins/style-check";
import PenaltyCard from "@/components/Layout/Common/PenaltyCard.vue";
@Component({
  components: {
    Loader,
    DetailHeader,
    Empty,
    "inner-image-zoom": InnerImageZoom,
    PenaltyCard,
  },
})
export default class UserDetail extends mixins(
  Navigation,
  Permissions,
  CustomDate,
  StyleCheck
) {
  loader = false;
  createdProductsPaginationLoader = false;
  wishlistPaginationLoader = false;
  commentsOnProductPaginationLoader = false;
  userBillsPaginationLoader = false;
  userAccountsPaginationLoader = false;
  penaltiesPaginationLoader = false;
  tab = 0;

  private async created(): Promise<void> {
    this.loader = true;
    await this.$store
      .dispatch("users/getUserById", this.$route.params.id)
      .catch(() => {
        const Error: Notification = {
          message: this.$t("Users.fetchError.userById", {
            name: this.$route.params.id,
          }) as string,
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);
      });
    await this.$store
      .dispatch("users/getUserBalanceById", this.$route.params.id)
      .catch(() => {
        const Error: Notification = {
          message: this.$t("Users.fetchError.userBalance", {
            name: this.$route.params.id,
          }) as string,
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);
      });

    await this.getAccounts(
      this.accountsPagination.page,
      this.accountsPagination.itemsPerPage
    );

    await this.getProductsByWishlist(
      this.wishlistPagination.page,
      this.wishlistPagination.itemsPerPage
    );

    await this.getCreatedProductsByUser(
      this.createdProductsPagination.page,
      this.createdProductsPagination.itemsPerPage
    );
    await this.getPenaltiesByUser(
      this.penaltiesPagination.page,
      this.penaltiesPagination.itemsPerPage
    );
    await this.getCommentsOnProductsByUser(
      this.commentsOnProductsPagination.page,
      this.commentsOnProductsPagination.itemsPerPage
    );

    await this.getAllBillsByUser(
      this.userBillsPagination.page,
      this.userBillsPagination.itemsPerPage
    );

    await this.$store
      .dispatch("kyc/getUserKYC", this.$route.params.id)
      .catch(() => {
        const Error: Notification = {
          message: this.$tc("Views.ukyc-e1"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);
      });

    this.setNavigation({
      previousRoute: "/staff/users",
      icon: "mdi-account",
      title: this.user.email as string,
    });
    this.loader = false;
  }

  private get user(): User {
    return this.$store.getters["users/getUser"];
  }

  private get userBalance(): User {
    return this.$store.getters["users/getUserBalance"];
  }

  //Wishlist
  wishlistPagination = {
    itemsPerPage: parseInt(process.env.VUE_APP_DEFAULT_PAGINATION),
    totalItems: 0,
    page: 1,
  };

  private async getProductsByWishlist(
    page: number,
    size: number,
    combine = false
  ): Promise<void> {
    await this.$store
      .dispatch("wishlist/getWishlistByUser", {
        page,
        size,
        user_id: this.$route.params.id,
        combine: combine,
      })
      .catch(() => {
        const Error: Notification = {
          message: this.$tc("Views.ud-e1"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);
      });
  }

  private get wishlist() {
    let response = this.$store.getters["wishlist/getWishlistByUser"];
    this.wishlistPagination.totalItems = response.totalItems;
    this.wishlistPagination.page = parseInt(response.currentPage);

    if (response?.wishlist) {
      return response.wishlist;
    } else {
      return [];
    }
  }

  private get totalWishlistPages() {
    const total =
      this.wishlistPagination.totalItems / this.wishlistPagination.itemsPerPage;
    if (total > Math.round(total)) {
      return Math.round(total) + 1;
    } else {
      return Math.round(total);
    }
  }

  private async showMoreWishlist() {
    this.wishlistPaginationLoader = true;
    await this.getProductsByWishlist(
      this.wishlistPagination.page + 1,
      this.wishlistPagination.itemsPerPage,
      true
    );
    this.wishlistPaginationLoader = false;
  }
  //End Wishlist

  //Created products
  createdProductsPagination = {
    itemsPerPage: parseInt(process.env.VUE_APP_DEFAULT_PAGINATION),
    totalItems: 0,
    page: 1,
  };

  private async getCreatedProductsByUser(
    page: number,
    size: number,
    combine = false
  ): Promise<void> {
    await this.$store
      .dispatch("products/getCreatedProductsByUser", {
        page,
        size,
        user_id: this.$route.params.id,
        combine: combine,
      })
      .catch(() => {
        const Error: Notification = {
          message: this.$tc("Views.ud-e2"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);
      });
  }

  private get createdProducts() {
    let response = this.$store.getters["products/getProducts"];
    this.createdProductsPagination.totalItems = response.totalItems;
    this.createdProductsPagination.page = parseInt(response.currentPage);

    if (response?.products) {
      return response.products;
    } else {
      return [];
    }
  }

  private get totalCreatedProductsPages() {
    const total =
      this.createdProductsPagination.totalItems /
      this.createdProductsPagination.itemsPerPage;
    if (total > Math.round(total)) {
      return Math.round(total) + 1;
    } else {
      return Math.round(total);
    }
  }

  private async showMoreCreatedProducts() {
    this.createdProductsPaginationLoader = true;
    await this.getCreatedProductsByUser(
      this.createdProductsPagination.page + 1,
      this.createdProductsPagination.itemsPerPage,
      true
    );
    this.createdProductsPaginationLoader = false;
  }
  //End Created products

  //Penalties
  penaltiesPagination = {
    itemsPerPage: parseInt(process.env.VUE_APP_DEFAULT_PAGINATION),
    totalItems: 0,
    page: 1,
  };

  private async getPenaltiesByUser(
    page: number,
    size: number,
    combine = false
  ): Promise<void> {
    await this.$store
      .dispatch("penalties/getClientPenalties", {
        page,
        size,
        client: this.$route.params.id,
        combine: combine,
      })
      .catch(() => {
        const Error: Notification = {
          message: this.$tc("Profile.penalties.fetchError"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);
      });
  }

  get penalties() {
    let response = this.$store.getters["penalties/getPenalties"];
    this.penaltiesPagination.totalItems = response.totalItems;
    this.penaltiesPagination.page = parseInt(response.currentPage);

    if (response?.penalties) {
      return response.penalties;
    } else {
      return [];
    }
  }

  get totalPenaltiesPages() {
    const total =
      this.penaltiesPagination.totalItems /
      this.penaltiesPagination.itemsPerPage;
    if (total > Math.round(total)) {
      return Math.round(total) + 1;
    } else {
      return Math.round(total);
    }
  }

  async showMorePenalties() {
    this.penaltiesPaginationLoader = true;
    await this.getPenaltiesByUser(
      this.penaltiesPagination.page + 1,
      this.penaltiesPagination.itemsPerPage,
      true
    );
    this.penaltiesPaginationLoader = false;
  }
  // End penalties

  //Comments on products
  commentsOnProductsPagination = {
    itemsPerPage: parseInt(process.env.VUE_APP_DEFAULT_PAGINATION),
    totalItems: 0,
    page: 1,
  };

  private async getCommentsOnProductsByUser(
    page: number,
    size: number,
    combine = false
  ): Promise<void> {
    await this.$store
      .dispatch("comments/getProductCommentsByUser", {
        page,
        size,
        user_id: this.$route.params.id,
        combine: combine,
      })
      .catch(() => {
        const Error: Notification = {
          message: this.$tc("Views.ud-e3"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);
      });
  }

  private get comments() {
    let response = this.$store.getters["comments/getProductComments"];
    this.commentsOnProductsPagination.totalItems = response.totalItems;
    this.commentsOnProductsPagination.page = parseInt(response.currentPage);

    if (response?.comments) {
      return response.comments;
    } else {
      return [];
    }
  }

  private get totalCommentsOnProductPages() {
    const total =
      this.commentsOnProductsPagination.totalItems /
      this.commentsOnProductsPagination.itemsPerPage;
    if (total > Math.round(total)) {
      return Math.round(total) + 1;
    } else {
      return Math.round(total);
    }
  }

  private async showMoreCommentsOnProduct() {
    this.commentsOnProductPaginationLoader = true;
    await this.getCommentsOnProductsByUser(
      this.commentsOnProductsPagination.page + 1,
      this.commentsOnProductsPagination.itemsPerPage,
      true
    );
    this.commentsOnProductPaginationLoader = false;
  }
  //End Comments on products

  //Start user bills
  userBillsPagination = {
    itemsPerPage: parseInt(process.env.VUE_APP_DEFAULT_PAGINATION),
    totalItems: 0,
    page: 1,
  };

  private async getAllBillsByUser(
    page: number,
    size: number,
    combine = false
  ): Promise<void> {
    await this.$store
      .dispatch("bill/getUserBillsForDetail", {
        page,
        size,
        user_id: this.$route.params.id,
        operation: "all",
        combine: combine,
      })
      .catch(() => {
        const Error: Notification = {
          message: this.$tc("Views.udi-e1"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);
      });
  }

  private get bills() {
    let response = this.$store.getters["bill/getUserBills"];
    this.userBillsPagination.totalItems = response.totalItems;
    this.userBillsPagination.page = parseInt(response.currentPage);

    if (response?.bills) {
      return response?.bills;
    } else {
      return [];
    }
  }

  private get totalUserBillsPages() {
    const total =
      this.userBillsPagination.totalItems /
      Number(process.env.VUE_APP_DEFAULT_PAGINATION);
    if (total > Math.round(total)) {
      return Math.round(total) + 1;
    } else {
      return Math.round(total);
    }
  }

  private async showMoreUserBills() {
    this.userBillsPaginationLoader = true;
    await this.getAllBillsByUser(
      this.userBillsPagination.page + 1,
      this.userBillsPagination.itemsPerPage,
      true
    );
    this.userBillsPagination.page = this.userBillsPagination.page + 1;
    this.userBillsPaginationLoader = false;
  }
  //End user bills

  //USER KYC
  private get userKYC() {
    return this.$store.getters["kyc/getUserKYC"];
  }

  //User Withdrawal Accounts

  accountsPagination = {
    itemsPerPage: parseInt(process.env.VUE_APP_DEFAULT_PAGINATION),
    totalItems: 0,
    page: 1,
  };

  private async getAccounts(page: number, size: number, combine = false) {
    await this.$store
      .dispatch("users/getUserAccounts", {
        id: this.$route.params.id,
        page,
        size,
        combine: combine,
      })
      .catch(() => {
        const Error: Notification = {
          message: this.$t("Views.uw-e1", {
            name: this.$route.params.id,
          }) as string,
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);
      });
  }

  private get accounts() {
    let response = this.$store.getters["users/getUserAccounts"];
    this.accountsPagination.totalItems = response.totalItems;
    this.accountsPagination.page = parseInt(response.currentPage);

    return response;
  }

  private get totalUserAccountsPages() {
    const total =
      this.accountsPagination.totalItems /
      Number(process.env.VUE_APP_DEFAULT_PAGINATION);
    if (total > Math.round(total)) {
      return Math.round(total) + 1;
    } else {
      return Math.round(total);
    }
  }

  private async showMoreUserAccounts() {
    this.userAccountsPaginationLoader = true;
    await this.getAccounts(
      this.accountsPagination.page + 1,
      this.accountsPagination.itemsPerPage,
      true
    );
    this.accountsPagination.page = this.accountsPagination.page + 1;
    this.userAccountsPaginationLoader = false;
  }
}
